
$main-bg: #1C3FAA;
$main-gradient: linear-gradient(180deg, rgba(29,13,245,1) 0%, rgba(15,7,123,1) 100%);
$dash-gradient: linear-gradient(48deg, rgba(28,63,170,1) 0%, rgba(28,62,168,1) 59%, rgba(14,32,85,1) 100%);

$text-color: #1C3FAA;
$box-shadow-input: 0px 6px 20px 0px rgba(28,63,170,0.4);
$box-shadow-input-low: 0px 6px 20px 0px rgba(28,63,170,0.1);
$box-shadow-card: 0px 18px 32px 0px rgba(76,147,255,0.38);
$title-font: 10px * 3; 
$box-shadow-btn: 0px 5px 30px 0px rgba(0,0,0,0.15);
$bg-img: '../Img/login-bg.png';
$hero-pic: '../Img/landbg.png';
$app-all: '../Img/app-view.png';
$footImg: '../Img/footBG.png';



html {
    scroll-behavior: smooth;
  }
* , a, .txt{
    font-family: 'Lato', sans-serif;
    // color: $text-color;
}

a{
    color: $text-color; 
}
a:hover{
    text-decoration: none;
}

body{
    background: #F5F5FD;
}

@mixin formContainer($size, $color, $pad, $margin, $border  ) {
    width: $size;
    background-color: $color;
    padding: $pad;
    margin-top: $margin;
    border-radius: $border;
  }

  .formContainer-1{
    @include formContainer(450px, transparent, 20px, 140px, 20px )
}

  .formContainer-2{
      @include formContainer(100%, transparent, 10px, 10px, 10px )
  }

  @media (max-width: 700px){
      .formContainer-1{
          background-color: white;
      }
      .formContainer-2{
        background-color: white;
    }
  }

  .logo{
    height: 35px;
    width: 130px;
}

.bg-blue{
    background: $text-color;
}
.notify{
    height: 25px;
    width: 25px;
}

.activity-icon{
    @extend .notify;
    width: 45px;
    height: 45px;
}
  .authContainer{
    background: url($bg-img);
    height: 100vh !important ;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
}

.hero-sec{
    background: url($hero-pic);
    height: 100vh !important ;
    width: 100%;
    background-size: cover;
}

.img-sec{
    background: url($footImg);
    background-size: cover;
    height: 100%;
    width: 100%;

}

.form-control{
    border: 1px solid #1C3FAA;
    box-shadow: $box-shadow-input-low;
    height: 48px;
    border-radius: 4px + 5;
}

.form-control:focus{
    box-shadow: $box-shadow-input;
}

.form-label{
    font-weight: bold;
    font-size: 4px * 4;
}

.title-text{
    font-size: $title-font;
    font-weight: bold;
}

.btn-action {
    background: $main-gradient;
    font-weight: bold;
    box-shadow: $box-shadow-btn;
    border: none;
}

.btn-create{
    background-color: white;
    border-radius: 50px;
    border: 2px solid $text-color;
    color: $text-color; 
}

.btn-create-two{
    background-color: white;
    border-radius: 50px;
    border: none;
    color: $text-color; 
    width: 70px;
}

.btn-cancel{
    @extend .btn-create;
    background: red;
    color: white;
    border: none;
    width: 130px;
    height: 40px;
}

.btn-save{
    @extend .btn-cancel;
    background: $text-color;
    color: white;
}

.btn-save-two{
    background: $text-color;
    color: white;
    border-radius: 50px;
    
}

.btn-filter-all{
    @extend .btn-save;
    background: white;
    color: $text-color;
    border: 1px solid $text-color;
    padding: 5px;
    width: 60px;
    height: 40px;
}

.btn-filter{
    @extend .btn-filter-all;
    background: transparent;
    border: none;
}
.stepwizard-step p {
	margin-top: 10px;
}

.stepwizard-row {
	display: table-row;
}

.stepwizard {
	display: table;
	width: 100%;
	position: relative;
}

.stepwizard-step button[disabled] {
	opacity: 1 !important;
	filter: alpha(opacity=100) !important;
}

.stepwizard-row:before {
	top: 14px;
	bottom: 0;
	position: absolute;
	content: " ";
	width: 100%;
	height: 2px;
	background-color:$text-color;
}

.stepwizard-step {
	display: table-cell;
	text-align: center;
	position: relative;
}

.btn-circle {
	width: 30px;
	height: 30px;
	text-align: center;
	padding: 6px 0;
	font-size: 12px;
	line-height: 1.428571429;
    border-radius: 15px;
    color: white;
    background-color: $text-color;

    p{
        width: 100px;
        align-content: center;
    }
}

.bg-lite{
    background-color: #FFFFFF;
}

.jumbotron-fluid{
    background: $dash-gradient ;
    height: 300px;
}

.dash-content{
    margin-top: -90px;
    z-index: 1;
}

.dash-icon{
    width: 100px;
    height: 100px;    
}

.card{
    transition: all .6s ease;
    border: none;
}

.card:hover{
    // box-shadow: $box-shadow-card;
    transform: scale(1.01, 1.01);
    animation: pulse 6s;
    transition: all .6s ease;
    z-index: 1;
}

.card-header{
    background: white;
}

.activity-card{
    height: 355px;
}

.activity-info{
    box-sizing: border-box;
    position: relative;
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.navbar-light .navbar-nav .nav-link{
    color: $text-color;
}

.breadcrum {
   
    ul {
        list-style: none;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      
      li {
        position: relative;
        background:#F5F5FD;
        color: $text-color;
        height: 30px;
        padding: 0 20px 0 40px;
        display:flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        font-size: 14px;
      }
      
      li::after {
          content: '';
          position: absolute;
          width: 5px;
          height: 15px;
          background:$text-color;
          top: 0;
          right: -10px;
          z-index: 9;
          border-right: 2px solid #fff;
          transform: skewX(45deg);
      }
      
      li::before {
            content: '';
          position: absolute;
          width: 5px;
          height: 15px;
          background:$text-color;
          bottom: 0;
          right: -10px;
          z-index: 9;
          border-right: 2px solid #fff;
          transform: skewX(-45deg);
      }      
}

.features-box{
    width: 80px;
    height: 80px;
    background: white;
    border-radius: 50%;
}


.features-sec{
    margin-top: 120px;
  }

  .app-view{
      height: 700px;
      background: url($app-all);
      background-size: cover;
      background-repeat: no-repeat;
  }

  .nav-tabs {
    border-bottom: 2px solid $text-color;
    background:white;
    padding: 15px 15px 0 15px;
    margin-bottom: 20px;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #495057;
    background-color: #fff;
    border-top: 2px solid $text-color;
    border-left: 2px solid $text-color;
    border-right: 2px solid $text-color;
    border-bottom: none;
}

.form-h{
    margin-left: 90px;
}

tr:hover{

    // box-shadow: $box-shadow-card;
    transform: scale(1.01, 1.01);
    animation: pulse 6s;
    transition: all .6s ease;
    z-index: 1;
}

input[type="checkbox"] { display: none; }

input[type="checkbox"] + label {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 20px;

  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

input[type="checkbox"] + label:last-child { margin-bottom: 0; }

input[type="checkbox"] + label:before {
  content: '';
  display: block;
  width: 20px;
  height: 20px;
  border: 1px solid red;
  position: absolute;
  left: 0;
  top: 0;
  opacity: .6;
  -webkit-transition: all .12s, border-color .08s;
  transition: all .12s, border-color .08s;
}

input[type="checkbox"]:checked + label:before {
  width: 10px;
  top: -5px;
  left: 5px;
  border-radius: 0;
  opacity: 1;
  border-top-color: transparent;
  border-left-color: transparent;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.btn-filter-all, .btn-filter{
    width: 80px;
}

.user-img{
    margin-top: 40px;
    position: absolute;
    top: -80px;
    left: 110px;
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: #ffffff;
    text-align: center;
    overflow: hidden;
    border: 5px solid #ffffff;
    z-index: 1;
}

hr{
    height: 1;
    background: red;
}

.no-b{
    border: none;
}

.b-right{
    border-right: 4px solid $text-color;
}

.card-user{
    border-radius: 20px;
}

.card-user:hover{
    border: 2px solid $text-color;
    z-index: inherit;
}
.txt-color{
    color: $text-color;
}


.custom-ui{
    text-align: center;
    width: 500px;
    padding: 40px;
    background: $main-bg;
    box-shadow: 0 20px 75px rgba(0, 0, 0, 0.23);
    color: #fff;
    }

    .custom-ui > h1 {
    margin-top: 0;
}

.custom-ui > button {
    width: 160px;
    padding: 10px;
    border: 1px solid #fff;
    margin: 10px;
    cursor: pointer;
    background: none;
    color: #fff;
    font-size: 14px;
    }
 
.dropdown-menu.show{
    border-radius: 12px;
    width: auto;
}

.text-cut{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

input[type=radio]{
    transform:scale(1.8);
  }

.product-desc{
    height: 40px;

    small{
        overflow: hidden;
        text-overflow: ellipsis;
        display: inline-block;
        white-space: nowrap;
        max-width: 100%;
    }
    
  }
 
  .custom-file-label{
      margin: 4px;
  }
  
  sup {
      top: -.5em;
      position: relative;
      font-size: 75%;
      line-height: 0;
      vertical-align: baseline;
  }
  .product-img-circle{
      border-radius: 50%;
      width: 40px;
      height: 40px;
      box-shadow: $box-shadow-input-low;
  }

  .input-group-text{
    background: #F5F5FD;
    cursor: pointer;
  }

  .chart-cont{
      height: 400px;
  }